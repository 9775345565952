import { FormattedMessage, IntlProvider } from "react-intl";
import messages from "../Message/messages";
import { useEffect, useState } from "react";
import "./styles.css";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import logoPSA from "../../Login/login-logo.png";

const Step4 = (props) => {
  const { isLogin = true } = props;

  const [locale, setLocale] = useState("en");
  useEffect(() => {
    if (!sessionStorage.getItem("locale")) {
      sessionStorage.setItem("locale", "en");
    }
    const sessionLocale = sessionStorage.getItem("locale");
    setLocale(sessionLocale);
  }, []);
  const navigate = useNavigate();

  const handleDone = () => {
    if (isLogin) navigate("/sendFish");
    else navigate("/login");
  };

  const handleBack = () => {
    if (isLogin) navigate("/fish/setting/2fa3");
    else navigate("/fish/setting-login/2fa3");
  };
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <div className="containerFish">
        <div className="fish-box">
          <p style={{ fontSize: "24px", fontWeight: 600 }}>
            <img
              src={logoPSA}
              alt="logo authentication microsoft"
              width={80}
              style={{ marginRight: 10 }}
            />
            <FormattedMessage id="settingTitle" />
          </p>
          <hr style={{ height: "1px", width: "100%" }} />
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <div>
              <p>
                <FormattedMessage id="settingDoneContent" />
              </p>
            </div>

            <div style={{ gap: 10, display: "flex" }}>
              {/* <Button onClick={handleBack}>
                <FormattedMessage id="back" />
              </Button> */}
              <Button onClick={handleDone}>
                <FormattedMessage id="done" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </IntlProvider>
  );
};
export default Step4;
