import { FormattedMessage, IntlProvider } from "react-intl";
import messages from "../Message/messages";
import { useEffect, useState } from "react";
import "./styles.css";
import Button from "../Button/Button";
import { Box, TextField } from "@mui/material";
import { Field, Formik } from "formik";
import { useNavigate, useResolvedPath } from "react-router-dom";
import PopupNotQr from "../Popup/PopupNotQr";
import QRCode from "qrcode.react";
import axios from "axios";
import logoPSA from "../../Login/login-logo.png";

const Step3 = (props) => {
  const { isLogin } = props;
  var constants;

  if (process.env.REACT_APP_ENV === "local") {
    constants = require("../lib/constant-local");
  } else if (process.env.REACT_APP_ENV === "dev") {
    constants = require("../lib/constant-dev");
  } else if (process.env.REACT_APP_ENV === "uat") {
    constants = require("../lib/constant-uat");
  } else if (process.env.REACT_APP_ENV === "prod") {
    constants = require("../lib/constant-prod");
  } else {
    constants = require("../lib/constant");
  }
  const [locale, setLocale] = useState("en");
  const navigate = useNavigate();
  const urlOtp = constants.READ_OTP_2FA;
  const urlVerify = constants.VERIFY_OTP;
  const [openPopup, setOpenPopup] = useState(false);
  const [urlQr, setUrlQr] = useState("");
  const { search } = useResolvedPath(urlQr);
  const param = new URLSearchParams(search);
  const tokenSession = sessionStorage.getItem("sessionToken");
  const emailSession = sessionStorage.getItem("email");
  const validateNumber = (value) => {
    let error;
    if (!/^\d{6}$/.test(value)) {
      error = "validateCode";
    }
    return error;
  };

  useEffect(() => {
    if (!sessionStorage.getItem("locale")) {
      sessionStorage.setItem("locale", "en");
    }
    const sessionLocale = sessionStorage.getItem("locale");
    setLocale(sessionLocale);
  }, []);
  const handleOpenPopup = () => {
    setOpenPopup(!openPopup);
  };

  useEffect(() => {
    getQR();
    // eslint-disable-next-line
  }, []);

  const getQR = () => {
    axios
      .post(urlOtp, {
        username: tokenSession,
      })
      .then((res) => {
        setUrlQr(res.data.otpURI);
      })
      .catch((err) => {});
  };

  const handleNext = (values, { setFieldError }) => {
    axios
      .post(urlVerify, { username: tokenSession, otpVal: values.code })
      .then((res) => {
        if (res.data.status === "error") {
          setFieldError("code", "incorrectCode");
        } else {
          if (isLogin) navigate("/fish/setting/2fa4");
          else navigate("/fish/setting-login/2fa4");
          sessionStorage.setItem("is2fa", true);
        }
      })
      .catch();
  };

  const handleBack = () => {
    if (isLogin) navigate("/fish/setting/2fa2");
    else navigate("/fish/setting-login/2fa2");
  };

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Formik initialValues={{ code: "" }} onSubmit={handleNext}>
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <div className="containerFish">
            <div className="fish-box" style={{ position: "relative" }}>
              <p style={{ fontSize: "24px", fontWeight: 600 }}>
                <img
                  src={logoPSA}
                  alt="logo authentication microsoft"
                  width={80}
                  style={{ marginRight: 10 }}
                />
                <FormattedMessage id="settingTitle" />
              </p>
              <hr style={{ height: "1px", width: "100%" }} />
              <Box overflow={"auto"} padding={"0 20px"}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <div>
                    <p style={{ fontWeight: 600 }}>
                      <FormattedMessage id="step1" />
                    </p>
                    <p>
                      <FormattedMessage id="contentStep1" />
                    </p>
                    <QRCode value={urlQr} renderAs="canvas" width={"150px"} />
                    <p
                      onClick={handleOpenPopup}
                      style={{
                        textDecoration: "underline",
                        color: "blueviolet",
                        cursor: "pointer",
                      }}
                    >
                      <FormattedMessage id="notScan" />
                    </p>
                    <p style={{ fontWeight: 600 }}>
                      <FormattedMessage id="step2" />
                    </p>
                    <p>
                      <FormattedMessage id="contentStep2" />
                    </p>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <form id="form-setting" onSubmit={handleSubmit}>
                        <div
                          style={{
                            gap: 10,
                            display: "flex",
                            marginLeft: "auto",
                          }}
                        >
                          <FormattedMessage id="verifyCode" />
                          <Field
                            name="code"
                            validate={validateNumber}
                            render={({ field }) => {
                              return (
                                <TextField
                                  {...field}
                                  abelProps={{
                                    style: {
                                      right:
                                        locale === "ar" ? "0px" : undefined,
                                      left: locale === "ar" ? "0" : undefined,
                                    },
                                    classes: {
                                      root: { minWidth: "200px" },
                                    },
                                  }}
                                  variant="outlined"
                                  size="small"
                                  className="custom-email-field"
                                  error={
                                    touched.code &&
                                    errors.code && (
                                      <FormattedMessage id={errors.code} />
                                    )
                                  }
                                  helperText={
                                    touched.code && errors.code ? (
                                      <FormattedMessage id={errors.code} />
                                    ) : (
                                      ""
                                    )
                                  }
                                />
                              );
                            }}
                          />
                        </div>

                        <div
                          style={{
                            gap: 10,
                            display: "flex",
                            // position: "absolute",
                            // bottom: 10,
                          }}
                        >
                          <Button onClick={handleBack} type="button">
                            <FormattedMessage id="back" />
                          </Button>

                          <Button type="submit" form="form-setting">
                            <FormattedMessage id="next" />
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          </div>
        )}
      </Formik>
      <PopupNotQr
        open={openPopup}
        onClosePopup={handleOpenPopup}
        account={emailSession}
        secretKey={param.get("secret")}
        locale={locale}
      />
    </IntlProvider>
  );
};
export default Step3;
