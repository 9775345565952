import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import "./Footer.css";
import fishnewsEN from "./FishNewsbtn.png";
import faqfishEN from "./FishFAQbtn.png";
import sendplaneEN from "./SendFishbtn.png";
import logoutbtnEN from "./Logoutbtn.png";
import fishnewsID from "./ID/FishNewsbtnID.png";
// import faqfishID from "./FishFAQbtn.png";
import sendplaneID from "./ID/SendFishbtnID.png";
import logoutbtnID from "./ID/LogoutbtnID.png";
import fishnewsZH from "./ZH/FishNewsbtnZH.png";
// import faqfishZH from "./ZH/FishFAQbtn.png";
import sendplaneZH from "./ZH/SendFishbtnZH.png";
import logoutbtnZH from "./ZH/LogoutbtnZH.png";
import fishnewsIT from "./IT/FishNewsbtnIT.png";
// import faqfishIT from "./IT/FishFAQbtn.png";
import sendplaneIT from "./IT/SendFishbtnIT.png";
import logoutbtnIT from "./IT/LogoutbtnIT.png";
import fishnewsKO from "./KO/FishNewsbtnKO.png";
//import faqfishKO from "./KO/FishFAQbtnKO.png";
import sendplaneKO from "./KO/SendFishbtnKO.png";
import logoutbtnKO from "./KO/LogoutbtnKO.png";
import fishnewsNL from "./NL/FishNewsbtnNL.png";
// import faqfishNL from "./NL/FishFAQbtn.png";
import sendplaneNL from "./NL/SendFishbtnNL.png";
import logoutbtnNL from "./NL/LogoutbtnNL.png";
import fishnewsTR from "./TR/FishNewsbtnTR.png";
// import faqfishTR from "./FishFAQbtn.png";
import sendplaneTR from "./TR/SendFishbtnTR.png";
import logoutbtnTR from "./TR/LogoutbtnTR.png";
import fishnewsAR from "./AR/FishNewsbtnAR.png";
import faqfishAR from "./AR/FishFAQbtnAR.png";
import sendplaneAR from "./AR/SendFishbtnAR.png";
import logoutbtnAR from "./AR/LogoutbtnAR.png";
import sendSpanish from "./PA/spanish_sendfish.png";
import fishNewSpanish from "./PA/spanish_fishnews.png";
import logOutSpanish from "./PA/spanish_logout.png";
import faqSpanish from "./PA/spanish_FAQ.png";

// import fish
import { IntlProvider, FormattedMessage } from "react-intl";
import messages from "../Message/messages";
import { Navigate, useNavigate, Link } from "react-router-dom";
import fishFAQpdf from "../Asset/FAQonFISHsendingplatforms.pdf";

const Footer = () => {
  const navigate = useNavigate();
  var constants;

  if (process.env.REACT_APP_ENV === "local") {
    constants = require("../lib/constant-local");
  } else if (process.env.REACT_APP_ENV === "dev") {
    constants = require("../lib/constant-dev");
  } else if (process.env.REACT_APP_ENV === "uat") {
    constants = require("../lib/constant-uat");
  } else if (process.env.REACT_APP_ENV === "prod") {
    constants = require("../lib/constant-prod");
  } else {
    constants = require("../lib/constant");
  }
  const fishFAQURL = constants.FAQ;
  const fishNewsURL = constants.FISHNEWS;
  const [locale, setLocale] = useState("en");
  const handleLogout = () => {
    sessionStorage.clear();
  };

  const redirectFAQ = () => {
    navigate({ pathname: "/pdfView/faq" });
  };
  useEffect(() => {
    if (!sessionStorage.getItem("locale")) {
      sessionStorage.setItem("locale", "en");
    }
    const sessionLocale = sessionStorage.getItem("locale");
    setLocale(sessionLocale);
  }, []);
  const buttonImages = {
    en: {
      fishnews: fishnewsEN,
      fishfaq: faqfishEN,
      sendfish: sendplaneEN,
      logout: logoutbtnEN,
    },
    id: {
      fishnews: fishnewsID,
      fishfaq: faqfishEN,
      sendfish: sendplaneID,
      logout: logoutbtnID,
    },
    zh: {
      fishnews: fishnewsZH,
      fishfaq: faqfishEN,
      sendfish: sendplaneZH,
      logout: logoutbtnZH,
    },
    nl: {
      fishnews: fishnewsNL,
      fishfaq: faqfishEN,
      sendfish: sendplaneNL,
      logout: logoutbtnNL,
    },
    it: {
      fishnews: fishnewsIT,
      fishfaq: faqfishEN,
      sendfish: sendplaneIT,
      logout: logoutbtnIT,
    },
    ko: {
      fishnews: fishnewsKO,
      fishfaq: faqfishEN,
      sendfish: sendplaneKO,
      logout: logoutbtnKO,
    },
    tr: {
      fishnews: fishnewsTR,
      fishfaq: faqfishEN,
      sendfish: sendplaneTR,
      logout: logoutbtnTR,
    },
    ar: {
      fishnews: fishnewsAR,
      fishfaq: faqfishAR,
      sendfish: sendplaneAR,
      logout: logoutbtnAR,
    },
    "es-PA": {
      fishnews: fishNewSpanish,
      sendfish: sendSpanish,
      fishfaq: faqSpanish,
      logout: logOutSpanish,
    },
    "es-AR": {
      fishnews: fishNewSpanish,
      sendfish: sendSpanish,
      logout: logOutSpanish,
      fishfaq: faqSpanish,
    },
  };
  const images = buttonImages[locale] || buttonImages.en;
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Navbar className="fishfooter ">
        <Container>
          <a href={fishNewsURL} className="button" target="_blank">
            <img src={images.fishnews} alt="Fish News" className="icon-news" />
            {/* <span className="text">
              <FormattedMessage id="fish_news" />
            </span> */}
          </a>
          {/* <div className="fishnewsBtn">
            <div>
              <a
                className="fishnewsLink"
                href="https://globalpsa.sharepoint.com/sites/FishCulture-and-OD/SitePages/FISH-related-Awards.aspx "
                rel="noreferrer"
              >
                <img src={fishnews} className="fishnewsPic"></img>
                <span>
                  <FormattedMessage id="fish_news" />
                </span>
              </a>
            </div>
          </div> */}
          <a
            // to="/view-faq"
            // className="button"
            // target="_blank"
            onClick={redirectFAQ}
            className="button"
            target="_blank"
          >
            <img src={images.fishfaq} alt="Fish FAQ" className="icon-faq" />
            {/* <span className="text">
              <FormattedMessage id="FAQs" />
            </span> */}
          </a>
          {/* <div className="faqfishBtn">
            <a
              className="faqfishLink"
              href="http://www.google.com"
              rel="noreferrer"
            >
              <img src={faqfish} className="faqfishPic"></img>
              <span>
                <FormattedMessage id="FAQs" />
              </span>
            </a>
          </div> */}
          <Link to="/sendfish" className="button">
            <img src={images.sendfish} alt="Send Fish" className="icon-send" />
            {/* <span className="text">
              <FormattedMessage id="send_fish_to" />
            </span> */}
          </Link>
          {/* <div className="sendfishFooterBtn">
            <a className="sendfishFooterLink" href="/sendfish" rel="noreferrer">
              <div>
                {" "}
                <img src={sendplane} className="sendfishFooterPic"></img>
              </div>
              <div></div>
              <span>
                <FormattedMessage id="send_fish_to" />
              </span>
            </a>
          </div> */}
          <Link to="/logout" className="button" onClick={handleLogout}>
            <img src={images.logout} alt="Send Fish" className="icon-logout" />
            {/* <span className="text">
              <FormattedMessage id="logout" />
            </span> */}
          </Link>
          {/* <div className="logoutBtn">
            <a className="Logout" href="/logout" onClick={handleLogout}>
              <span>
                <FormattedMessage id="logout" />
              </span>
            </a>
          </div> */}
        </Container>
      </Navbar>
    </IntlProvider>
  );
};

export default Footer;
