import axios from "axios";
import { Formik, Field } from "formik";
import React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./FALogin.css";
import { IntlProvider, FormattedMessage } from "react-intl";
import messages from "../Component/Message/messages";
import { TextField } from "@mui/material";
import welcomefish from "./WelcomeFish.png";

const FALogin = () => {
  var constants;

  if (process.env.REACT_APP_ENV === 'local') {
    constants = require("../Component/lib/constant-local");
  }
  else if (process.env.REACT_APP_ENV === 'dev') {
    constants = require("../Component/lib/constant-dev");
  }
  else if (process.env.REACT_APP_ENV === 'uat') {
    constants = require("../Component/lib/constant-uat");
  }
  else if (process.env.REACT_APP_ENV === 'prod') {
    constants = require("../Component/lib/constant-prod");
  }
  else {
    constants = require("../Component/lib/constant");
  }
  const loginURL = constants.LOGIN;
  const navigate = useNavigate();
  const location = useLocation();
  const [locale, setLocale] = useState("en");
  function containsSpecialCharacters(input) {
    // The pattern allows for one or more digits (\d+) and one or more special characters ([!@#$%^&*()_+{}|:"<>?[\]\\;',./~`-])
    const pattern = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return pattern.test(input);
  }
  const validateNumber = (value) => {
    let error;
    if (!/^\d{6}$/.test(value)) {
      error = "validateCode";
    }
    return error;
  };

  useEffect(() => {
    if (!sessionStorage.getItem("locale")) {
      sessionStorage.setItem("locale", "en");
    }
    const sessionLocale = sessionStorage.getItem("locale");
    setLocale(sessionLocale);
  }, []);

  function handleOnSubmit(values, { setFieldError }) {
    const data = {
      ...location.state.loginDetail,
      otpVal: values.code,
      otpRef: location.state.otpRef,
    };
    axios
      .post(loginURL, data)
      .then((res) => {
        const status = res.data[0].status;
        const token = res.data[0].sessionToken;
        const name = res?.data[0]?.name;
        if (status === "ok") {
          sessionStorage.setItem("sessionToken", token);
          sessionStorage.setItem("login", "true");
          sessionStorage.setItem("email", location.state.loginDetail.username);
          sessionStorage.setItem("name", name);
          sessionStorage.setItem("is2fa", true);
          if (containsSpecialCharacters(location.state.loginDetail.password)) {
            navigate("/sendFish");
          } else {
            navigate("/setpassword.jsp");
          }
        } else {
          setFieldError("code", "incorrectCode");
        }
      })
      .catch((err) => setFieldError("code", "incorrectCode"));
  }

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <div className="fa-loginbg">
        <Formik initialValues={{ code: "" }} onSubmit={handleOnSubmit}>
          {({ values, errors, touched, handleChange, handleSubmit }) => {
            return (
              <div className="fa-login">
                <div className="fa-login-form">
                  <div className="fa-left">
                    <div className="fa-login-left-inside">
                      <div className="fa-welcome">
                        <span>WELCOME TO</span>
                        <span className="fa-bold-welcome">FISHAPP</span>
                      </div>
                      <img
                        src={welcomefish}
                        className="fa-welcomefishPic"
                        alt="welcome"
                      />
                      <div className="fa-illustration-div">
                        <div className="fa-illustration">
                          All illustrations &copy; 2023 Caroline Lim
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="fa-right"
                    dir={locale === "ar" ? "rtl" : "ltr"}
                  >
                    <form className="fa-login-right" onSubmit={handleSubmit}>
                      <div className="fa-login-group">
                        <label
                          style={{ fontWeight: 700 }}
                          className={
                            locale === "ar"
                              ? "fa-selectLanguage-ar"
                              : "fa-selectLanguage"
                          }
                        >
                          <FormattedMessage
                            id="faTitle"
                            defaultMessage="TWO- STEP AUTHENTICATION"
                          />
                        </label>
                      </div>
                      <p style={{ maxWidth: "460px" }}>
                        <FormattedMessage id="faContent" />
                      </p>
                      <div>
                        <div className="fa-login-group">
                          <div>
                            <Field
                              name="code"
                              validate={validateNumber}
                              render={({ field }) => {
                                return (
                                  <TextField
                                    {...field}
                                    pladerhocel
                                    label={
                                      <FormattedMessage
                                        id="code"
                                        defaultMessage="Enter verification code"
                                      />
                                    }
                                    InputLabelProps={{
                                      // shrink: true,
                                      style: {
                                        right:
                                          locale === "ar" ? "0px" : undefined,
                                        left: locale === "ar" ? "0" : undefined,
                                      },
                                      classes: {
                                        root: { minWidth: "200px" },
                                      },
                                    }}
                                    variant="standard"
                                    size="small"
                                    error={
                                      touched.code &&
                                      errors.code && (
                                        <FormattedMessage id={errors.code} />
                                      )
                                    }
                                    helperText={
                                      touched.code && errors.code ? (
                                        <FormattedMessage id={errors.code} />
                                      ) : (
                                        ""
                                      )
                                    }
                                    className="fa-custom-email-field"
                                  />
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="fa-submitbtn">
                        <button
                          className={
                            locale === "ar" ? "fa-submitbtn-ar" : "abc"
                          }
                          type="submit"
                        >
                          <FormattedMessage id="continue" />
                        </button>
                      </div>
                    </form>
                    <div className="fa-hr-msg-login">
                      BROUGHT TO YOU BY GROUP HR AND GROUP IT
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
        <div className="fa-warning-terms">
          <FormattedMessage id="warning" />
        </div>
      </div>
    </IntlProvider>
  );
};

export default FALogin;
