import React, { useEffect, useState } from "react";

import FishDate from "./FishDate";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FishNumber from "./FishNumber";
import "./FishInItem.css";
import { IntlProvider, FormattedMessage } from "react-intl";
import messages from "../Message/messages";
import TextTruncate from "react-text-truncate";
const FishInItem = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [locale, setLocale] = useState("en");
  useEffect(() => {
    if (!sessionStorage.getItem("locale")) {
      sessionStorage.setItem("locale", "en");
    }
    const sessionLocale = sessionStorage.getItem("locale");
    setLocale(sessionLocale);
  }, []);
  let message = props.message;
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <>
        <tr onClick={handleShow}>
          <td width="10%" align="center">
            <FishDate date={props.date} />
          </td>
          <td width="15%" align="left" className="fishSentName">
            {props.name}
          </td>
          <td width="20%" align="center">
            <FishNumber fishcount={props.fishcount} />
          </td>
          <td width="55%" align="left">
            {" "}
            <TextTruncate
              className="white-space"
              line={2}
              element="span"
              truncateText="..."
              text={props.message}
              textTruncateChild={<a href="#">Read on</a>}
            />
          </td>
          {/* <td align="left">{props.message}</td> */}
        </tr>

        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
        >
          <Modal.Header className="pop-up-modal-header">
            <Modal.Title>
              <h6>
                <FormattedMessage id="from" />
              </h6>
              <h6>{props.name}</h6>
              <h6>
                {" "}
                <FormattedMessage id="date" />
              </h6>
              <h6>{props.date.substring(0, 10)}</h6>
              <h6>
                <FormattedMessage id="fishnumber" />
              </h6>
              <h4>
                <FishNumber fishcount={props.fishcount} />
              </h4>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body className="pop-up-modal">
            <h6>
              <FormattedMessage id="message" />
            </h6>
            <p className="white-space">{props.message}</p>
          </Modal.Body>
          <Modal.Footer className="pop-up-modal">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </IntlProvider>
  );
};

export default FishInItem;
