import { FormattedMessage, IntlProvider } from "react-intl";
import messages from "../Message/messages";
import React, { useEffect, useState } from "react";
import "./styles.css";
import Button from "../Button/Button";
import Appstore from "./AppStore.png";
import GooglePlay from "./GooglePlay.png";
import { useNavigate } from "react-router-dom";
import Logo from "./logo-authentication-microsoft.png";
import logoPSA from "../../Login/login-logo.png";

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
const Step2 = (props) => {
  const { isLogin } = props;
  const [locale, setLocale] = useState("en");
  const [value, setValue] = React.useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (!sessionStorage.getItem("locale")) {
      sessionStorage.setItem("locale", "en");
    }
    const sessionLocale = sessionStorage.getItem("locale");
    setLocale(sessionLocale);
  }, []);
  const handleNext = () => {
    if (isLogin) navigate("/fish/setting/2fa3");
    else navigate("/fish/setting-login/2fa3");
  };
  const handleBack = () => {
    if (isLogin) navigate("/fish/setting/2fa1");
    else navigate("/fish/setting-login/2fa1");
  };

  const handleChange = (event) => {
    setValue(event?.target?.value);
  };
  console.log({ value });
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <div className="containerFish">
        <div className="fish-box">
          <p style={{ fontSize: "24px", fontWeight: 600 }}>
            <img
              src={logoPSA}
              alt="logo authentication microsoft"
              width={80}
              style={{ marginRight: 10 }}
            />
            <FormattedMessage id="settingTitle" />
          </p>
          <hr style={{ height: "1px", width: "100%" }} />
          <img src={Logo} alt="logo authentication microsoft" width={80} />
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              gap: "10px",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "400px",
              textAlign: "center",
            }}
          >
            <div>
              {/* <p>
                <FormattedMessage id="dowload" />
              </p> */}
              <p>
                <FormattedMessage id="install" />
              </p>
            </div>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                <FormattedMessage id="selectDevice" />
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="iphone"
                  control={<Radio />}
                  label="Iphone"
                />
                <FormControlLabel
                  value="android"
                  control={<Radio />}
                  label="Android"
                />
              </RadioGroup>
            </FormControl>
            <div>
              {value === "iphone" && (
                <a
                  href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
                  target="blank"
                >
                  <img src={Appstore} alt="app store" width={150} />
                </a>
              )}
              {value === "android" && (
                <a
                  href="https://play.google.com/store/search?q=microsoft+authenticator&c=apps"
                  target="blank"
                >
                  <img src={GooglePlay} alt="google play" width={150} />
                </a>
              )}
            </div>
            {!!value && (
              <div style={{ gap: 10, display: "flex" }}>
                <Button onClick={handleBack}>
                  <FormattedMessage id="back" />
                </Button>
                <Button onClick={handleNext}>
                  <FormattedMessage id="next" />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </IntlProvider>
  );
};
export default Step2;
