import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function deleteCookie(name) {
  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

const LandingPage = (props) => {
  const navigate = useNavigate();
  var constants;

  if (process.env.REACT_APP_ENV === 'local') {
    constants = require("../lib/constant-local");
  }
  else if (process.env.REACT_APP_ENV === 'dev') {
    constants = require("../lib/constant-dev");
  }
  else if (process.env.REACT_APP_ENV === 'uat') {
    constants = require("../lib/constant-uat");
  }
  else if (process.env.REACT_APP_ENV === 'prod') {
    constants = require("../lib/constant-prod");
  }
  else {
    constants = require("../lib/constant");
  }
  const [userIdValue1, setUserIdValue] = useState(null);
  const oktaDetailURL = constants.GETOKTAUSERINFO;
  useEffect(() => {
    console.log("landing22/6");
    const cookies = document.cookie.split(";");
    let userIdValue = null;

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith("userid=")) {
        userIdValue = cookie.substring("userid=".length, cookie.length);
        userIdValue = decodeURIComponent(userIdValue);
        console.log(userIdValue);
        setUserIdValue(userIdValue);
        deleteCookie("userid");

        break;
      }
    }

    const oktaDetails = {
      username: userIdValue1,
      origusername: "test",
    };
    console.log(oktaDetails);
    axios.post(oktaDetailURL, oktaDetails).then((res) => {
      const status = res.data[0].status;
      const token = res.data[0].userid;
      const name = res.data[0].name;
      console.log(res);
      console.log(status);
      if (status === "ok") {
        sessionStorage.setItem("sessionToken", token);
        sessionStorage.setItem("login", "true");
        sessionStorage.setItem("name", name);
        sessionStorage.setItem("eMail", oktaDetails.username);

        navigate("/sendFish");
      }
    });
  }, [userIdValue1]);

  return (
    <div>
      <p>landing</p>
    </div>
  );
};

export default LandingPage;
