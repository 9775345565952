import React from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";
import messages from "../Component/Message/messages";
import axios from "axios";

const ForgetPassword = (props) => {
    var constants;

    if (process.env.REACT_APP_ENV === 'local') {
        constants = require("../Component/lib/constant-local");
    }
    else if (process.env.REACT_APP_ENV === 'dev') {
        constants = require("../Component/lib/constant-dev");
    }
    else if (process.env.REACT_APP_ENV === 'uat') {
        constants = require("../Component/lib/constant-uat");
    }
    else if (process.env.REACT_APP_ENV === 'prod') {
        constants = require("../Component/lib/constant-prod");
    }
    else {
        constants = require("../Component/lib/constant");
    }

  const intl = useIntl();
  const email = sessionStorage.getItem("eMail");
  const forgetPasswordURL = constants.GETPASSWORD;
  const confirmForget = () => {
    alert(
      // email
      intl.formatMessage({
        id: "forgetpasswordalert",
        defaultMessage: "Please check your email for new password",
      })
    );
    axios
      .post(forgetPasswordURL, {
        username: (email && email.length === 0) ? email : props.tempEmail,
      })
      .then((res) => {
        console.log(res.data.status).catch((err) => {
          console.error(err);
        });
      });
  };
  return (
    <IntlProvider locale={props.locale} messages={messages[props.locale]}>
      <div>
        <a
          className="forgetpw"
          onClick={() => {
            const result = window.confirm(
              intl.formatMessage({
                id: "confirmForget",
                defaultMessage: "Click on OK to confirm password ",
              })
            );
            if (result) {
              confirmForget();
            }
          }}
        >
          <FormattedMessage id="forgetpassword" />
        </a>
      </div>
    </IntlProvider>
  );
};

export default ForgetPassword;
