import React from "react";
import { Link } from "react-router-dom";
import fishFAQpdf from "../Asset/FAQonFISHsendingplatforms.pdf";

const PdfComponent = () => {
  return (
    <embed
      src="/fish/FAQonFISHsendingplatforms.pdf"
      height="100%"
      width="100%"
      download="FAQonFISHsendingplatforms.pdf"
    ></embed>
  );
};

export default PdfComponent;
