import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { memo } from "react";
import Button from "../Button/Button";
import { FormattedMessage, IntlProvider } from "react-intl";
import messages from "../Message/messages";

const PopupNotQr = memo((props) => {
  const { onClickSuccess, onClosePopup, account, secretKey, open, locale } =
    props;

  const handleSuccess = () => {
    onClickSuccess && onClickSuccess();
    onClosePopup && onClosePopup();
  };

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Dialog open={open} keepMounted>
        <Grid container justify="center" alignItems="center">
          <DialogTitle>
            <FormattedMessage id="notScanTitle" />
          </DialogTitle>
          <DialogContent
            style={{ borderTop: "1px solid grey", padding: "20px" }}
          >
            <DialogContentText color={"black"}>
              <FormattedMessage id="notScanContent" />
            </DialogContentText>
          </DialogContent>
        </Grid>
        <Grid paddingX={"20px"}>
          <p>
            <FormattedMessage id="account" /> {account}
          </p>
          <p>
            <FormattedMessage id="secret" /> {secretKey}
          </p>
        </Grid>

        <Grid
          container
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <Button onClick={handleSuccess}>
            <FormattedMessage id="ok" />
          </Button>
        </Grid>
      </Dialog>
    </IntlProvider>
  );
});
export default PopupNotQr;
