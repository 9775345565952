import axios from "axios";
import { Formik, Field } from "formik";
import React from "react";
import { useState, useEffect, useMemo } from "react";
import { redirect, useNavigate } from "react-router-dom";
import "./FishLogin.css";
import { IntlProvider, FormattedMessage } from "react-intl";
import messages from "../Component/Message/messages";
import "./RememberMe.css";
import ForgetPassword from "./ForgetPassword";
import { Divider, TextField } from "@mui/material";
import welcomefish from "./WelcomeFish.png";
import logo from "./login-logo.png";
import PopupConfirm from "../Component/Popup/PopupConfirm";
const FishLogin = () => {
  var constants;

  if (process.env.REACT_APP_ENV === 'local') {
    constants = require("../Component/lib/constant-local");
  }
  else if (process.env.REACT_APP_ENV === 'dev') {
    constants = require("../Component/lib/constant-dev");
  }
  else if (process.env.REACT_APP_ENV === 'uat') {
    constants = require("../Component/lib/constant-uat");
  }
  else if (process.env.REACT_APP_ENV === 'prod') {
    constants = require("../Component/lib/constant-prod");
  }
  else {
    constants = require("../Component/lib/constant");
  }
  const baseURL = constants.CHECKOKTAUSER;
  const loginURL = constants.LOGIN;
  const oktaURL = constants.OKTALOGIN;
  const forgetPasswordURL = constants.GETPASSWORD;
  const [oktaStatus, setOktaStatus] = useState();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [remember, setRemember] = useState(false);
  const [locale, setLocale] = useState("en");
  const [mobile, setMobile] = useState(false);
  const [login, setLogin] = useState(false);
  const [errLogin, setErrLogin] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    const emailValue = localStorage.getItem("email");
    const rememberMeValue = localStorage.getItem("rememberMe");
    if (emailValue && rememberMeValue) {
      setEmail(emailValue);
      setRemember(JSON.parse(rememberMeValue));
    }
  }, []);

  useMemo(() => {
    const emailValue = localStorage.getItem("email");
    setEmail(emailValue);
  }, []);

  const handleLocaleChange = (e) => {
    setLocale(e.target.value);
    sessionStorage.setItem("locale", e.target.value);
  };

  const handleLoginEmail = () => {
    setLogin(true);
  };

  const handleLoginOkta = () => {
    window.location.assign(oktaURL);
  };

  const handleOpenPopup = () => {
    setOpenPopup(!openPopup);
  };

  const handelSuccess = () => {
    navigate("/fish/setting-login/2fa1");
  };

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <div className="loginbg">
        <Formik
          initialValues={{ email: email, language: "", password: "" }}
          onSubmit={(values, { setFieldError }) => {
            const loginDetail = {
              username: values.email,
              deviceToken: "",
              password: values.password,
              origusername: "",
            };

            setEmail(loginDetail.username);
            if (!values.email || !values.password) {
              if (!values.email) setFieldError("email", "Please Enter Email");
              if (!values.password)
                setFieldError("password", "Please Enter Password");
            } else {
              setErrLogin(false);
              axios.post(loginURL, loginDetail).then((res) => {
                const status = res?.data[0]?.status;
                const token = res?.data[0]?.sessionToken;
                const name = res?.data[0]?.name;
                const error = res?.data[0]?.error;
                const is2fa =
                  res?.data[0]?.is_otp_enabled === "Y" ? true : false;
                const otpRef = res?.data[0]?.otp_ref;
                if (rememberMe) {
                  localStorage.setItem("eMail", loginDetail.username);
                }
                if (!rememberMe) {
                  localStorage.setItem("eMail", "");
                }
                sessionStorage.setItem("eMail", values.email);
                setEmail(values.email);
                if (!is2fa && error && error?.includes("expired")) {
                  navigate("/changepassword.jsp");
                } else {
                  if (status === "ok") {
                    if (is2fa) {
                      navigate("/login/2fa", {
                        state: { loginDetail, otpRef },
                      });
                    } else {
                      sessionStorage.setItem("sessionToken", token);
                      sessionStorage.setItem("email", loginDetail.username);
                      // sessionStorage.setItem("name", name);
                      // sessionStorage.setItem("login", "true");
                      // sessionStorage.setItem("is2fa", is2fa);
                      setOpenPopup(true);
                    }
                  } else {
                    setErrLogin(true);
                  }
                }
              });
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <div className="login">
              <div className="login-form">
                <div className="left">
                  <div className="login-left-inside">
                    <div className="welcome">
                      <span>WELCOME TO</span>{" "}
                      <span className="bold-welcome">FISHAPP</span>
                    </div>
                    <img
                      src={welcomefish}
                      className="welcomefishPic"
                      alt="welcome"
                    />
                    <div className="illustration-div">
                      {" "}
                      <div className="illustration">
                        All illustrations &copy; 2023 Caroline Lim
                      </div>
                    </div>
                  </div>
                </div>

                <div className="right" dir={locale === "ar" ? "rtl" : "ltr"}>
                  <form className="login-right" onSubmit={handleSubmit}>
                    <div className="login-group">
                      <label
                        className={
                          locale === "ar"
                            ? "selectLanguage-ar"
                            : "selectLanguage"
                        }
                      >
                        <FormattedMessage id="selectlanguage" />
                      </label>
                      <select
                        className="languages"
                        name="language"
                        onChange={handleLocaleChange}
                        defaultValue="en"
                      >
                        <option value="ar" label="ARABIC">
                          ARABIC
                        </option>
                        <option value="id" label="BAHASA INDONESIA">
                          BAHASA INDONESIA
                        </option>
                        <option value="zh" label="CHINESE">
                          CHINESE
                        </option>
                        <option value="nl" label="DUTCH">
                          DUTCH
                        </option>
                        <option value="en" label="ENGLISH">
                          ENGLISH
                        </option>
                        <option value="it" label="ITALIAN">
                          ITALIAN
                        </option>
                        <option value="ko" label="KOREAN">
                          KOREAN
                        </option>
                        <option value="tr" label="TURKISH ">
                          TURKISH
                        </option>
                        <option value="es-PA" label="SPANISH (PANAMA)">
                          SPANISH
                        </option>
                        <option value="es-AR" label="SPANISH (ARGENTINA)">
                          SPANISH
                        </option>
                      </select>
                    </div>
                    {login ? (
                      <>
                        <div>
                          <div className="login-group">
                            <div>
                              <Field
                                name="email"
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    label={
                                      <FormattedMessage
                                        id="email"
                                        defaultMessage="Test"
                                      />
                                    }
                                    InputLabelProps={{
                                      style: {
                                        right:
                                          locale === "ar" ? "0px" : undefined,
                                        left: locale === "ar" ? "0" : undefined,
                                      },
                                      classes: {
                                        root: { minWidth: "200px" },
                                      },
                                    }}
                                    variant="standard"
                                    size="small"
                                    error={touched.email && errors.email}
                                    className="custom-email-field"
                                  />
                                )}
                              />
                              <div className="error-text">
                                {errors.email && touched.email ? (
                                  <div>{errors.email}</div>
                                ) : null}
                              </div>
                              <div>
                                <div className="rememberme">
                                  <input
                                    type="checkbox"
                                    checked={remember}
                                    onChange={(event) => {
                                      setRemember(event.target.checked);
                                      const isChecked = event.target.checked;
                                      if (isChecked) {
                                        localStorage.setItem(
                                          "rememberMe",
                                          JSON.stringify(isChecked)
                                        );
                                        localStorage.setItem(
                                          "email",
                                          values.email
                                        );
                                      } else {
                                        localStorage.removeItem("rememberMe");
                                        localStorage.setItem("email", "");
                                      }
                                    }}
                                  />
                                  <label>
                                    <FormattedMessage id="rememberme" />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="login-group">
                            <div>
                              <Field
                                name="password"
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    type="password"
                                    label={
                                      <FormattedMessage
                                        id="password"
                                        defaultMessage="Test"
                                      />
                                    }
                                    InputLabelProps={{
                                      style: {
                                        right:
                                          locale === "ar" ? "0px" : undefined,
                                        left: locale === "ar" ? "0" : undefined,
                                      },
                                      classes: {
                                        root: { minWidth: "200px" },
                                      },
                                    }}
                                    variant="standard"
                                    size="small"
                                    error={touched.password && errors.password}
                                    className="custom-email-field"
                                  />
                                )}
                              />
                              <div className="error-text">
                                {errors.password && touched.password ? (
                                  <div>{errors.password}</div>
                                ) : null}
                              </div>
                              <div>
                                <ForgetPassword locale={locale} tempEmail={values.email}/>
                              </div>
                            </div>
                          </div>
                          <div className="error-text">
                            {errLogin ? (
                              <FormattedMessage id="incorrectPW" />
                            ) : null}
                          </div>
                          <div>
                            <span className="terms">
                              <FormattedMessage id="terms" />
                            </span>
                          </div>
                        </div>
                        <div className="submitbtn ">
                          <button
                            className={locale === "ar" ? "submitbtn-ar" : "abc"}
                            type="submit"
                          >
                            <FormattedMessage id="login" />
                          </button>
                        </div>
                      </>
                    ) : (
                      <div
                        style={{
                          margin: "30px 0",
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <img
                          src={logo}
                          alt="logo"
                          width={"20%"}
                          style={{ margin: "auto" }}
                        />
                        <div className="btn ">
                          <button onClick={handleLoginOkta}>
                            <FormattedMessage id="loginWithOkta" />
                          </button>
                        </div>
                        <Divider textAlign="center">
                          <p
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              margin: 0,
                            }}
                          >
                            OR
                          </p>
                        </Divider>
                        <div className="btn ">
                          <button onClick={handleLoginEmail}>
                            <FormattedMessage id="loginWithEmail" />
                          </button>
                        </div>
                      </div>
                    )}
                  </form>
                  <div className="hr-msg-login">
                    BROUGHT TO YOU BY GROUP HR AND GROUP IT
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>

        <div className="warning-terms">
          <FormattedMessage id="warning" />
        </div>
      </div>
      <PopupConfirm
        open={openPopup}
        title="titleSetting2faPopup"
        text="contentSetting2faPopup"
        onClosePopup={handleOpenPopup}
        onClickSuccess={handelSuccess}
        locale={locale}
      />
    </IntlProvider>
  );
};

export default FishLogin;
