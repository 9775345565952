import React from 'react';
import Iframe from 'react-iframe';
const FishPDFViewer = ({ pdfURL }) => {
    return (
        <div>
            <Iframe url={pdfURL} width="100%" height="100%" position="absolute" allowFullScreen />
        </div>
    );
};

export default FishPDFViewer;
