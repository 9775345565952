module.exports = Object.freeze({
  DOMAIN: "https://fish-uat.globalpsa.com/AuthServer/",
  LOGIN: "https://fish-uat.globalpsa.com/AuthServer/login/",
  GETPASSWORD: "https://fish-uat.globalpsa.com/AuthServer/getPassword/",
  CHANGEPASSWORD: "https://fish-uat.globalpsa.com/AuthServer/changePassword/",
  CHECKOKTAUSER: "https://fish-uat.globalpsa.com/AuthServer/getOktaUser/",
  GETOKTAUSERINFO:
    "https://fish-uat.globalpsa.com/AuthServer/getOktaUserInformation",
  GETINBOX: "https://fish-uat.globalpsa.com/AuthServer/getInbox/",
  GETUSERLISTNEW: "https://fish-uat.globalpsa.com/AuthServer/getUserListNew",
  GETOUTBOX: "https://fish-uat.globalpsa.com/AuthServer/getOutbox/",
  GETINOUTCOUNT: "https://fish-uat.globalpsa.com/AuthServer/getInOutCount",
  OKTALOGIN: "https://fish-uat.globalpsa.com/login/",
  SENDFISH: "https://fish-uat.globalpsa.com/AuthServer/sendFish/",
  FAQ: "https://globalpsa.sharepoint.com/:b:/r/sites/FishappTechnicalTeam/Shared%20Documents/General/CRs/PWA/Fish%20FAQ_Final.pdf?csf=1&web=1&e=8UtfXi",
  FISHNEWS: "https://globalpsa.sharepoint.com/sites/FishCulture-and-OD ",
  ANOTHER_CONSTANT: "another value",
  REMOVE_2FA:
      "https://fish-uat.globalpsa.com/AuthServer/otp-management/otp-removal",
  READ_OTP_2FA:
      "https://fish-uat.globalpsa.com/AuthServer/otp-management/otp-setup",
  VERIFY_OTP:
      "https://fish-uat.globalpsa.com/AuthServer/otp-management/otp-verify",
  ITEM_PER_PAGE: 20,
});
