import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { createSearchParams, json, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import {
  useFormik,
  Field,
  FormikProvider,
  Form,
  Formik,
  ErrorMessage,
} from "formik";
import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import apple from "./SENDFISHTO.png";
import "./FishForm.css";
import ballon from "./FishBallon.png";
import { IntlProvider, FormattedMessage } from "react-intl";
import messages from "../Message/messages";
import * as Yup from "yup";
// import background from "./background8.png";

const FishForm = () => {
  const [locale, setLocale] = useState("en");
  const validationSchema = Yup.object().shape({
    message: Yup.string().required(<FormattedMessage id="emptyMessageAlert"/>),
  });
  const filterOptions = createFilterOptions({
    limit: 200,
    stringify: (option) => option.name + " " + option.department,
  });
  const theme = useTheme();
  const buttonClass = locale === "ko" ? "send-btn-kr" : "send-btn";
  const navigate = useNavigate();
  const [apiValues, setApiValues] = useState([]);
  const CustomizedSelectForFormik = ({ children, form, field }) => {
    const { name, value } = field;
    const { setFieldValue } = form;
    return (
      <Select
        name={name}
        value={value}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
        style={{
          fontSize: "16px",
          fontWeight: "500",
          borderRadius: "0",
          height: "2em",
          opacity: "0.7",
          borderWidth: "0.5px !important",
          borderColor: "#000000",
          borderStyle: "solid",
          borderBottomWidth: "1px",
          borderLeftWidth: "1px",
          borderRightWidth: "1px",
          borderTopWidth: "1px",
          width: "200px",
        }}
      >
        {children}
      </Select>
    );
  };
  var constants;
  if (process.env.REACT_APP_ENV === "local") {
    constants = require("../lib/constant-local");
  } else if (process.env.REACT_APP_ENV === "uat") {
    constants = require("../lib/constant-uat");
  } else if (process.env.REACT_APP_ENV === "prod") {
    constants = require("../lib/constant-prod");
  } else {
    constants = require("../lib/constant");
  }
  const getUserListURL = constants.GETUSERLISTNEW;
  const baseURL = constants.SENDFISH;
  const sessionToken = sessionStorage.getItem("sessionToken");
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const initialValues = {
    recipients: [],
    message: "",
    fishes: 4,
    cc: [],
  };
  const submit = (values) => {
    // console.log(values);
    if (!values.recipients || values.recipients.length === 0) {
      // console.log("no recipient");
    } else {
      console.log(JSON.stringify(values, null, 2));
      const recipientsId = values.recipients.map((recipient) => recipient.id);
      const fishesNumber = values.fishes;
      const fishnumber = [];
      for (var i = 0; i < fishesNumber; i++) {
        fishnumber.push(1);
      }
      let cc = values.cc.map((cc) => cc.id);
      // console.log(cc);
      let ccID = [];
      if (cc !== undefined) {
        ccID = cc;
      }

      // const message = values.message;
      let message = values.message;
      message = values.message.replace(/["'“”]/g, '\\"');

      message = encodeURIComponent(message);

      // if (message.includes('"') || message.includes("'")) {

      //   message = message.replace(/\\/g, "\\\\");
      //   message = JSON.stringify(message);

      //   //message = message.replace(///)
      // } else {
      //   message = encodeURIComponent(message);
      // }
      //console.log(message);
      //const breakMessage = message.replace('"', '\\"');
      const recipientsEmail = values.recipients.map(
        (recipient) => recipient.name
      );
      sessionStorage.setItem("sentEmail", recipientsEmail);

      const fishesValue = {
        source: "web",
        principle1: "YES",
        principle2: "YES",
        principle3: "YES",
        principle4: "YES",
        username: sessionToken,
        recipients: recipientsId,
        message: message,
        fishes: fishnumber,
        cc: ccID,
        principleText: "",
        language: "EN",
      };
      //console.log(JSON.stringify(fishesValue) + "watch this");
      axios
        .post(baseURL, fishesValue)
        .then((res) => {
          //console.log("sent" + res.data[0]);
          setApiValues(res.data[0]);
          navigate("/fishSent");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const [nameList, setNameList] = useState([]);
  const loadnameList = () => {
    const userEmail = sessionStorage.getItem("eMail");
    const userIDBody = { userid: userEmail };
    // axios.get("http://localhost:5000/api/get").then((res) => {
    //   setNameList(res.data);
    //   console.log("success");
    // });

    // if (!localStorage.getItem("userList")) {
    axios
      .post(getUserListURL, userIDBody)
      .then((res) => {
        // console.log(res);
        const nameList = res.data.sort(sortByProperty("name"));
        setNameList(nameList);
        localStorage.setItem("userList", nameList);
        console.log("name list success");
      })
      .catch((error) => {
        console.error("Error fetching user list:", error);
      });
    //   } else {
    //     try {
    //       const storedList = localStorage.getItem("userList");
    //       const parsedList = JSON.parse(storedList);
    //       setNameList(parsedList);
    //     } catch (error) {
    //       console.error("Error parsing user list from local storage:", error);
    //     }
    //   }
  };
  useEffect(() => {
    if (!sessionStorage.getItem("locale")) {
      sessionStorage.setItem("locale", "en");
    }
    const sessionLocale = sessionStorage.getItem("locale");
    setLocale(sessionLocale);
    loadnameList();
  }, []);
  function sortByProperty(property) {
    return function (a, b) {
      if (a[property] > b[property]) return 1;
      else if (a[property] < b[property]) return -1;
      return 0;
    };
  }
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <div
        className="fishFormContent"
        // style={{
        //   backgroundImage: `url(${background})`,
        //   backgroundSize: "1400px 560px",
        // }}
      >
        <Formik
          initialValues={initialValues}
          validateOnBlur="false"
          validateOnChange="false"
          onSubmit={submit}
          validationSchema={validationSchema}
        >
          {({ handleChange, values, setFieldValue }) => (
            // <Container maxWidth="sm">
            <Form className="form-big">
              {/* <Stack spacing={2}> */}

              <div
                className={
                  locale === "ar" ? "form-left form-left-ar" : "form-left"
                }
                dir={locale === "ar" ? "rtl" : "ltr"}
              >
                <div className="fish-left-inside">
                  <div className="fish-today">
                    {" "}
                    <span className="send-word">SEND</span>{" "}
                    <span className="bold-fish">FISH </span>
                    <span className="today-word">TODAY</span>{" "}
                  </div>
                  <div
                    className="send-fish-fields"
                    dir={locale === "ar" ? "rtl" : "ltr"}
                  >
                    <Autocomplete
                      size="small"
                      multiple
                      filterOptions={filterOptions}
                      disablePortal
                      className="recipients-field"
                      name="recipients"
                      id="recipients"
                      options={nameList}
                      getOptionLabel={
                        (option) => option.name + " , " + option.department
                        // option.name
                      }
                      onChange={(e, value) =>
                        setFieldValue(
                          "recipients",
                          value !== null ? value : initialValues.recipients
                        )
                      }
                      renderOption={(props, option) => (
                        <Box component="li" {...props} key={option.id}>
                          {option.name}, {option.department}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          className="reciepient-text"
                          label=<FormattedMessage
                            id="to"
                            defaultMessage="Test"
                          />
                          InputLabelProps={{
                            shrink: locale === "ar" ? "true" : undefined,
                            style: {
                              right: locale === "ar" ? "-120px" : undefined,
                              // left: locale === "ar" ? "120" : undefined,
                            },
                          }}
                          sx={{
                            color: "blue",
                            fontWeight: "bolder",
                            "& .MuiAutocomplete-inputRoot": {
                              [theme.breakpoints.down("sm")]: {
                                width: "230px",
                              },
                              [theme.breakpoints.up("md")]: {
                                width: "420px",
                              },
                              paddingLeft: "20px !important",
                              borderRadius: "15px",
                              height: "auto",
                              minHeight: "40px",
                            },
                            "& .MuiInputBase-input::placeholder": {
                              color: "green", // change the color of the placeholder text
                            },
                            // "& .MuiInputBase-input": {
                            //   fontSize: "9px", // Set font size for input text
                            // },
                          }}
                          // InputProps={{
                          //   style: {
                          //     alignSelf: "center",
                          //     borderRadius: "25px",
                          //     height: "35px",
                          //   },
                          // }}
                        />
                      )}
                      limitTags={1}
                    ></Autocomplete>
                    <Autocomplete
                      limitTags={1}
                      size="small"
                      multiple
                      filterOptions={
                        filterOptions
                        //   (options, { inputValue }) => {
                        //   console.log(options);
                        //   if (inputValue.length > 2) {
                        //     return filterOptions(options, { inputValue });
                        //   }
                        //   return options;
                        // }
                      }
                      disablePortal
                      name="cc"
                      id="cc"
                      options={nameList}
                      getOptionLabel={(option) =>
                        option.name + " , " + option.department
                      }
                      onChange={(e, value) =>
                        setFieldValue(
                          "cc",
                          value !== null ? value : initialValues.cc
                        )
                      }
                      renderOption={(props, option) => (
                        <Box component="li" {...props} key={option.id}>
                          {option.name} , {option.department}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label=<FormattedMessage
                            id="cc"
                            defaultMessage="Test"
                          />
                          InputLabelProps={{
                            shrink: locale === "ar" ? "true" : undefined,
                            style: {
                              right: locale === "ar" ? "-120px" : undefined,
                              //left: locale === "ar" ? "120" : undefined,
                            },
                          }}
                          sx={{
                            "& .MuiAutocomplete-inputRoot": {
                              [theme.breakpoints.down("sm")]: {
                                width: "230px",
                              },
                              [theme.breakpoints.up("md")]: {
                                width: "420px",
                              },
                              paddingLeft: "20px !important",
                              borderRadius: "15px",
                              height: "auto",
                              // minHeight: "40px",
                              // maxHeight: "80px",
                              color: "black ",
                            },
                          }}
                          name="cc"
                          // className="custom-cc-field"
                        />
                      )}
                    ></Autocomplete>
                    <div className="form-fishNumber">
                      <span className={locale === "ar" ? "span-ar2" : "abc"}>
                        <FormattedMessage id="fishnumber" />
                      </span>
                      <FormControl>
                        <Field
                          name="fishes"
                          component={CustomizedSelectForFormik}
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                        </Field>
                      </FormControl>
                    </div>
                  </div>
                  <div className="illustration-div2">
                    {" "}
                    <div className="illustration">
                      All illustrations &copy; 2023 Caroline Lim
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-right" dir={locale === "ar" ? "rtl" : "ltr"}>
                <div className="fish-right-inside">
                  <div className="message-title">
                    <span className={locale === "ar" ? "span-ar" : "abc"}>
                      {" "}
                      <FormattedMessage
                        id="your_message"
                        defaultMessage="Enter your message here"
                      />
                    </span>
                  </div>

                  <TextField
                    id="message"
                    name="message"
                    multiline="true"
                    minRows={8}
                    maxRows={8}
                    //inputProps={{ maxLength: 1000 }}
                    onChange={handleChange}
                    value={values.message}
                    variant="outlined"
                    // placeholder={

                    // }
                    className="custom-message-box"
                  ></TextField>
                  <div className="errormsgdiv">
                    <ErrorMessage
                      name="message"
                      component="div"
                      className="error-message1"
                    />
                  </div>

                  <div className="fish-send-btn">
                    {" "}
                    {/* <Button
                      className="send-btn"
                      type="submit"
                      variant="contained"
                    >
                      <img src={apple} />
                      Send
                    </Button> */}
                    <button className={buttonClass} type="submit">
                      <img
                        className="custom-button-img"
                        src={apple}
                        alt="Submit"
                      />
                      <span className="send-btn-next">
                        <FormattedMessage id="send" />
                      </span>
                    </button>
                  </div>
                </div>
                <div className="hr-msg">
                  BROUGHT TO YOU BY GROUP HR AND GROUP IT
                </div>
                {/* </Stack> */}
              </div>
            </Form>
            // </Container>
          )}
        </Formik>
      </div>
    </IntlProvider>
  );
};

export default FishForm;
