import { FormattedMessage, IntlProvider } from "react-intl";
import messages from "../Message/messages";
import { useEffect, useState } from "react";
import "./styles.css";
import PopupConfirm from "../Popup/PopupConfirm";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Stack } from "@mui/material";
import Logo from "./logo-authentication-microsoft.png";
import logoPSA from "../../Login/login-logo.png";
const Step1 = (props) => {
  const { isLogin } = props;
  var constants;

  if (process.env.REACT_APP_ENV === "local") {
    constants = require("../lib/constant-local");
  } else if (process.env.REACT_APP_ENV === "dev") {
    constants = require("../lib/constant-dev");
  } else if (process.env.REACT_APP_ENV === "uat") {
    constants = require("../lib/constant-uat");
  } else if (process.env.REACT_APP_ENV === "prod") {
    constants = require("../lib/constant-prod");
  } else {
    constants = require("../lib/constant");
  }
  const [locale, setLocale] = useState("en");
  const navigate = useNavigate();
  const tokenSession = sessionStorage.getItem("sessionToken");
  const urlRemove = constants.REMOVE_2FA;
  const is2faSession = sessionStorage.getItem("is2fa");
  const [openPopup, setOpenPopup] = useState(false);
  const [err, setErr] = useState("");
  useEffect(() => {
    if (!sessionStorage.getItem("locale")) {
      sessionStorage.setItem("locale", "en");
    }
    const sessionLocale = sessionStorage.getItem("locale");
    setLocale(sessionLocale);
  }, []);

  const handleOpenPopup = () => {
    setOpenPopup(!openPopup);
  };

  const handleSetup = () => {
    if (isLogin) navigate("/fish/setting/2fa2");
    else navigate("/fish/setting-login/2fa2");
  };

  const handleRemove = () => {
    axios
      .post(urlRemove, { username: tokenSession })
      .then((res) => {
        sessionStorage.clear();
        navigate("/login");
      })
      .catch((err) => setErr("errRemove2fa"));
  };

  const handleBack = () => {
    if (isLogin) navigate("/sendFish");
    else navigate("/login");
  };

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <div className="containerFish">
        <div className="fish-box">
          <p style={{ fontSize: "24px", fontWeight: 600 }}>
            <img
              src={logoPSA}
              alt="logo authentication microsoft"
              width={80}
              style={{ marginRight: 10 }}
            />
            <FormattedMessage id="settingTitle" />
          </p>
          <hr style={{ height: "1px", width: "100%" }} />
          <p
            style={{
              fontSize: "20px",
              padding: "0 100px",
              paddingBottom: "20px",
              textAlign: "center",
            }}
          >
            <FormattedMessage id="settingContentfa1" />
          </p>
          <Stack direction={"column"} gap={"20px"}>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <img alt="logo-authentication-microsoft" src={Logo} width={46} />
              <Stack direction={"column"}>
                <span style={{ fontSize: "18px", fontWeight: 600 }}>
                  <FormattedMessage id="microsoftAuthenticator" />
                </span>
                <span>
                  <FormattedMessage id="contentSetupAuthenticator" />
                </span>
              </Stack>
            </div>
            <Stack direction={"row"} gap={"10px"} marginLeft={"auto"}>
              <Button onClick={handleBack}>
                <FormattedMessage id="back" />
              </Button>
              {is2faSession === "true" ? (
                <Button onClick={handleOpenPopup}>
                  <FormattedMessage id="remove" />
                </Button>
              ) : (
                <Button onClick={handleSetup}>
                  <FormattedMessage id="setUp" />
                </Button>
              )}
            </Stack>
          </Stack>
        </div>
      </div>
      <PopupConfirm
        open={openPopup}
        title="titleRemovePopup"
        text="contentRemovePopup"
        onClosePopup={handleOpenPopup}
        onClickSuccess={handleRemove}
        locale={locale}
        err={err}
        type={"yes/no"}
      />
    </IntlProvider>
  );
};
export default Step1;
