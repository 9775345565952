import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { memo } from "react";
import Button from "../Button/Button";
import messages from "../Message/messages";
import { FormattedMessage, IntlProvider } from "react-intl";

const PopupConfirm = memo((props) => {
  const { onClickSuccess, onClosePopup, title, text, open, locale, err, type } =
    props;

  const handleSuccess = () => {
    onClickSuccess && onClickSuccess();
    onClosePopup && onClosePopup();
  };

  const handleClose = () => {
    onClosePopup && onClosePopup();
  };

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Dialog open={open} keepMounted>
        <Grid container justify="center" alignItems="center">
          <DialogTitle>
            <FormattedMessage id={title} />
          </DialogTitle>
          <DialogContent
            style={{ borderTop: "1px solid grey", padding: "20px" }}
          >
            <DialogContentText>
              <FormattedMessage id={text} />
            </DialogContentText>
          </DialogContent>
        </Grid>
        <Grid
          container
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          {err && (
            <p style={{ color: "#ff1744" }}>
              <FormattedMessage id={err} />
            </p>
          )}
          {type === "yes/no" ? (
            <>
              {" "}
              <Button onClick={handleSuccess}>
                <FormattedMessage id="yes" />
              </Button>
              <Button onClick={handleClose}>
                <FormattedMessage id="no" />
              </Button>
            </>
          ) : (
            <Button onClick={handleSuccess}>
              <FormattedMessage id="setting" />
            </Button>
          )}
        </Grid>
      </Dialog>
    </IntlProvider>
  );
});
export default PopupConfirm;
