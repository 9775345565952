import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./ChangePW.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";
import messages from "../Message/messages";

const pattern = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required(
    <FormattedMessage id="passwordTempReq" />
  ),
  newPassword: Yup.string()
    .min(6, <FormattedMessage id="passwordMinChar" />)
    .required(<FormattedMessage id="passwordReq" />)
    .test("uppercase", <FormattedMessage id="passwordUpperCase" />, (value) => {
      return /[A-Z]/.test(value);
    })
    .test("lowercase", <FormattedMessage id="passwordLowerCase" />, (value) => {
      return /[a-z]/.test(value);
    })
    .test("number", <FormattedMessage id="passwordNumber" />, (value) => {
      return /\d/.test(value);
    })
    .test(
      "SpecialChars",
      <FormattedMessage id="passwordSpecialChars" />,
      (value) => {
        return pattern.test(value);
      }
    )
    .test(
      "not-same-as-current",
      <FormattedMessage id="passwordNotSame" />,
      function (value) {
        const currentPassword = this.resolve(Yup.ref("currentPassword"));
        return currentPassword !== value;
      }
    ),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("newPassword"), null],
      <FormattedMessage id="passwordMatch" />
    )
    .required(<FormattedMessage id="passwordConfirmedReq" />),
});

const ChangePW = () => {
  const [locale, setLocale] = useState("en");
  const navigate = useNavigate();
  var constants;

  if (process.env.REACT_APP_ENV === 'local') {
    constants = require("../lib/constant-local");
  }
  else if (process.env.REACT_APP_ENV === 'dev') {
    constants = require("../lib/constant-dev");
  }
  else if (process.env.REACT_APP_ENV === 'uat') {
    constants = require("../lib/constant-uat");
  }
  else if (process.env.REACT_APP_ENV === 'prod') {
    constants = require("../lib/constant-prod");
  }
  else {
    constants = require("../lib/constant");
  }
  const loginURL = constants.LOGIN;
  const changePWURL = constants.CHANGEPASSWORD;
  useEffect(() => {
    if (!sessionStorage.getItem("locale")) {
      sessionStorage.setItem("locale", "en");
    }
    const sessionLocale = sessionStorage.getItem("locale");
    setLocale(sessionLocale);
  }, []);
  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    // console.log("submit");
    const currentPassword = values.currentPassword;
    const user = sessionStorage.getItem("eMail");
    const loginDetail = {
      username: user,
      deviceToken: "",
      password: currentPassword,
      origusername: "test",
    };
    const passwordChange = {
      username: user,
      newpassword: values.newPassword,
    };
    try {
      const response = await axios.post(loginURL, loginDetail);
      // console.log(response.data[0].status);
      if (response.data[0].status === "ok") {
        // console.log("currentPW ok ");
        const changePasswordResponse = await axios.post(
          changePWURL,
          passwordChange
        );
        //console.log(changePasswordResponse.data[0].status);
        if (changePasswordResponse.data[0].status === "ok") {
          navigate("/pwSuccess");
          console.log();
        } else {
          // console.log(changePasswordResponse.data.status);
          setFieldError("newPassword", changePasswordResponse.data[0].error);
        }
      } else {
        console.log(response.data[0].status);
        setFieldError("currentPassword", "Incorrect password");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setSubmitting(false);
  };
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <div className="PW">
        <div className="changePW">
          <h1>
            <FormattedMessage id="changePW" />
          </h1>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="pwFields">
                <label htmlFor="currentPassword">
                  <FormattedMessage id="tempPW" />
                </label>
                <Field
                  type="password"
                  id="currentPassword"
                  name="currentPassword"
                />
                <ErrorMessage
                  name="currentPassword"
                  component="div"
                  className="error-message"
                />
              </div>

              <div>
                <label htmlFor="newPassword">
                  <FormattedMessage id="newPW" />
                </label>
                <Field type="password" id="newPassword" name="newPassword" />
                <ErrorMessage
                  name="newPassword"
                  component="div"
                  className="error-message"
                />
              </div>

              <div>
                <label htmlFor="confirmPassword">
                  <FormattedMessage id="confirmPW" />
                </label>
                <Field
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="error-message"
                />
              </div>

              <button type="submit">
                <FormattedMessage id="submit" />
              </button>
              <div className="pwMsg">
                <div>
                  - <FormattedMessage id="passwordMinChar" />
                </div>
                <div>
                  - <FormattedMessage id="passwordContains" />
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </IntlProvider>
  );
};

export default ChangePW;
