import React, { useEffect, useState } from "react";
import "./FishSent.css";
import { IntlProvider, FormattedMessage } from "react-intl";
import messages from "../Message/messages";

import background from "./background8.png";

const FishSent = () => {
  const [locale, setLocale] = useState("en");
  let toEmail = sessionStorage.getItem("sentEmail");
  toEmail = toEmail.replace(/,/g, ", ");
  useEffect(() => {
    if (!sessionStorage.getItem("locale")) {
      sessionStorage.setItem("locale", "en");
    }
    const sessionLocale = sessionStorage.getItem("locale");
    setLocale(sessionLocale);
  }, []);
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <div
        className="fishSent"
        // style={{
        //   backgroundImage: `url(${background})`,
        //   backgroundSize: "cover",
        // }}
      >
        <div className="fish-box">
          <FormattedMessage
            id="fish_sent"
            defaultMessage="Fish Message Sent successfully to {toEmail}"
            values={{ toEmail }}
          />
        </div>
      </div>
    </IntlProvider>
  );
};

export default FishSent;
