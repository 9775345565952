import React from "react";
import "../SendFish/FishSent.css";
const PWSuccess = () => {
  return (
    <div className="fishSent">
      <div className="fish-box">
        <div>Your Password has been updated successfully!</div>
      </div>
    </div>
  );
};

export default PWSuccess;
