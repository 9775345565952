import "./styles.css";

const Button = (props) => {
  const { title, onClick, children, ...rest } = props;
  return (
    <button className="container-button" onClick={onClick} {...rest}>
      {title ?? children}
    </button>
  );
};

export default Button;
