import { Navigate, useOutlet } from "react-router-dom";

import FishNavBar from "../Navigation/FishNavBar";

import PWAPrompt from "react-ios-pwa-prompt";
import Footer from "../Footer/Footer";
import "./Layout.css";

export const Layout = () => {
  //   const { user } = useAuth();
  const outlet = useOutlet();

  //   if (user) {
  //     return <Navigate to="/sendFish" replace />;
  //   }

  return (
    <div className="content">
      <div className="incontent">{outlet}</div>
      <PWAPrompt />
    </div>
  );
};
